import * as Yup from 'yup';

const validRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
const passwordRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,18}$/;
const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
const plainTextRegex = /^[A-Za-z\s]+$/;

export const loginValidationSchema = Yup.object({
  email: Yup.string()
    .trim()
    .matches(validRegex, 'Invalid email address')
    .email('Invalid email address')
    .required('Required'),
  password: Yup.string()
    .min(6, 'Password must be at least 6 characters')
    .required('Required'),
});

export const ChangePasswordValidationSchema = Yup.object({
  old_password: Yup.string()
    .min(6, 'Old Password must be at least 6 characters')
    .required('Required'),
  new_password: Yup.string()
    .matches(
      passwordRegex,
      'Password must have a capital letter, a small letter, a number, a symbol, and a length between 6 and 12 characters'
    )
    .min(6, 'New Password must be at least 6 characters')
    .required('Required'),
  confirm_password: Yup.string()
    .required('Required')
    .oneOf([Yup.ref('new_password')], 'Password must match'),
});

export const forgotPasswordValidationSchema = Yup.object({
  email: Yup.string()
    .trim()
    .matches(validRegex, 'Email cannot contain spaces at the beginning or end')
    .email('Invalid email address')
    .required('Required'),
});

export const resetPasswordValidationSchema = Yup.object({
  password: Yup.string()
    .matches(
      passwordRegex,
      'Password must have a capital letter, a small letter, a number, a symbol, and a length between 6 and 12 characters'
    )
    .min(6, 'Password must be at least 6 characters')
    .required('Required'),
  confirmPassword: Yup.string()
    .min(6, 'Password must be at least 6 characters')
    .required('Required')
    .oneOf([Yup.ref('password')], 'Passwords must match'),
});

export const addUserValidationSchema = Yup.object({
  firstname: Yup.string()
    .required('Required')
    .matches(plainTextRegex, 'Invalid firstname'),
  lastname: Yup.string()
    .required('Required')
    .matches(plainTextRegex, 'Invalid lastname'),
  email: Yup.string()
    .required('Required')
    .matches(emailRegex, 'Invalid email id'),
  role: Yup.string().required('Required'),
});

export const editUserValidationSchema = Yup.object({
  firstname: Yup.string()
    .required('Required')
    .matches(plainTextRegex, 'Invalid firstname'),
  lastname: Yup.string()
    .required('Required')
    .matches(plainTextRegex, 'Invalid lastname'),
  email: Yup.string()
    .required('Required')
    .matches(emailRegex, 'Invalid email id'),
  role: Yup.string().required('Required'),
  status: Yup.string().required('Required'),
});

export const roleValidationSchema = Yup.object({
  title: Yup.string().required('Required'),
  status: Yup.number().required('Required'),
});
