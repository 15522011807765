import React, { useEffect, useState } from 'react';
import { AuthStyle } from '../../Layouts/style';
import { Helmet } from 'react-helmet';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';
import {
  CloseEyeIcon,
  OpenEyeIcon,
  RightArrow,
  WhiteRightArrow,
} from '../../Icons/SVGContainer';
import useAuth from '../../Redux/Actions/authActions';
import { Formik } from 'formik';
import { loginValidationSchema } from '../../Utils/validators';

const Login = () => {
  const { login } = useAuth();

  const [rememberMe, setRememberMe] = useState(false);
  const [loginState, setLoginState] = useState({
    email: '',
    password: '',
  });

  useEffect(() => {
    const storedEmail = localStorage.getItem('mirasysRememberedEmail');
    if (storedEmail) {
      setLoginState({ ...loginState, email: storedEmail });
      setRememberMe(true);
      loginState.email = storedEmail;
    }
  }, []);

  const handleRememberMeChange = () => {
    setRememberMe(!rememberMe);
  };

  const handleSubmit = (values, { resetForm }) => {
    if (rememberMe) {
      localStorage.setItem('mirasysRememberedEmail', values.email);
    } else {
      localStorage.removeItem('mirasysRememberedEmail');
    }
    login(values);
  };
  const [password, setPassword] = useState(false);
  const togglePassword = () => {
    setPassword(!password);
  };
  return (
    <AuthStyle>
      <Helmet>
        <title>Mirasys: Login</title>
      </Helmet>
      <Box className="signup_area">
        <Box className="signup_left">
          <img src="/images/logo.svg" className="logo" />
          <Box className="signup_left_pic"></Box>
        </Box>
        <Box className="signup_right">
          <Typography className="sign_toplink">
            {/* I already have an account <Link to="/signup">Signup</Link> */}
          </Typography>
          <Box className="sign_heading">
            <Typography component="h2">Login</Typography>
            <Typography>Please login to your account</Typography>
          </Box>
          <Formik
            initialValues={loginState}
            validationSchema={loginValidationSchema}
            onSubmit={handleSubmit}
          >
            {(props) => (
              <form onSubmit={props.handleSubmit}>
                <Box className="form_box">
                  <Typography component="label">Email id</Typography>
                  <FormControl fullWidth>
                    <TextField
                      name="email"
                      placeholder="info@gmail.com"
                      value={props.values.email}
                      onChange={(e) => {
                        // Convert the entered email value to lowercase
                        const lowercasedEmail = e.target.value.toLowerCase();
                        // Call the Formik handleChange with the new lowercase value
                        props.handleChange({
                          target: {
                            name: 'email',
                            value: lowercasedEmail,
                          },
                        });
                      }}
                      error={props.touched.email && !!props.errors.email}
                      helperText={props.touched.email ? props.errors.email : ''}
                    />
                  </FormControl>
                </Box>
                <Box className="form_box">
                  <Typography component="label">Password</Typography>
                  <FormControl fullWidth>
                    <TextField
                      name="password"
                      type={password ? 'text' : 'password'}
                      placeholder="Type password"
                      onChange={props.handleChange}
                      error={props.touched.password && !!props.errors.password}
                      helperText={
                        props.touched.password ? props.errors.password : ''
                      }
                      InputProps={{
                        endAdornment: (
                          <IconButton
                            className="passwordIcon"
                            onClick={togglePassword}
                          >
                            {!password ? <CloseEyeIcon /> : <OpenEyeIcon />}
                          </IconButton>
                        ),
                      }}
                    />
                  </FormControl>
                  <Box className="check_con">
                    <Box className="checkbox_con">
                      <label>
                        <input
                          type="checkbox"
                          id="rememberMe"
                          name="rememberMe"
                          checked={rememberMe}
                          onChange={handleRememberMeChange}
                        />
                        Remember Me
                      </label>
                    </Box>
                    <Box className="forget_pass">
                      {/* <Link to="/forgot-password">Forget Password?</Link> */}
                    </Box>
                  </Box>
                </Box>

                <Box className="form_box">
                  <Button className="submitBtn" type="submit">
                    Login
                    <RightArrow />
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Box>
      </Box>
    </AuthStyle>
  );
};

export default Login;
