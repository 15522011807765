import React, { useState } from 'react';
import { HeaderStyle } from '../Layouts/style';
import { Box, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import { BellIcon, DownArrow } from '../Icons/SVGContainer';
import HeaderDateInfo from './HeaderDateInfo';
import { Link } from 'react-router-dom';
import useAuth from '../Redux/Actions/authActions';

const Header = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { user } = useAuth();
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { logout } = useAuth();

  const handleLogout = () => {
    logout();
  };
  return (
    <HeaderStyle>
      <Box className="header_area">
        <Box className="header_left">
          <Box className="header_logo">
            <img src="/images/logo_white.svg" />
          </Box>
          <HeaderDateInfo user={user} />
        </Box>
        <Box className="header_right">
          <IconButton>
            <BellIcon />
          </IconButton>
          <Box className="head_user_name">
            {/* <Box className="user_pic">
              <img src="/images/admin_pic.png" alt="" />
            </Box> */}
            <Box>
              <Typography component="h3">
                {user?.firstname + ' ' + user?.lastname}
              </Typography>
              <Typography component="h4">{user?.userType}</Typography>
            </Box>
          </Box>
          <IconButton className="header_drop_icon" onClick={handleClick}>
            <DownArrow />
          </IconButton>
          <Menu
            id="basic-menu"
            className="profile_toggle"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            {/* <MenuItem onClick={handleClose}>
              <Link to="#">Profile</Link>
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <Link to="/user-password-change">Change Password</Link>
            </MenuItem> */}
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
          </Menu>
        </Box>
      </Box>
    </HeaderStyle>
  );
};

export default Header;
