// @ts-check

import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import { Box, IconButton, Typography } from '@mui/material';
import { DialogStyle } from '../../Layouts/style';
import { CloseIcon } from '../../Icons/SVGContainer';

/**
 * @typedef {import('@mui/material/Dialog').DialogProps} DialogProps
 * @typedef {Object} CustomDialogProps
 * @property {boolean} open
 * @property {React.ReactNode} [children]
 * @property {() => void} onClose
 * @property {number} [width]
 * @property {number} [height]
 * @property {string} [title]
 */

/**
 * @param {CustomDialogProps} props
 */
function BaseModal(props) {
  const { open, onClose, children, width, height, title } = props;
  const [dialogHeight] = useState(height);
  const [dialogWidth] = useState(width ? width : '600px');

  return (
    <DialogStyle
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          height: dialogHeight,
          minWidth: dialogWidth,
          overflow: 'hidden',
          borderRadius: '20px',
          boxShadow: '0px 4px 40px 5px rgba(0, 0, 0, 0.13)',
        },
      }}
      sx={{
        '.MuiPaper-root': {
          minWidth: '700px !important',
        },
        '@media(max-width:1099px)': {
          '.MuiPaper-root': {
            minWidth: '60% !important',
          },
        },
        '@media(max-width:767px)': {
          '.MuiPaper-root': {
            minWidth: '90% !important',
          },
        },
      }}
    >
      <Box className="modal-head">
        <Box>
          {title && (
            <Typography className="global_modal_title">{title}</Typography>
          )}
        </Box>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>

      <Box className="modal-body">{children}</Box>
    </DialogStyle>
  );
}

export default BaseModal;
