import React from 'react';
import { Helmet } from 'react-helmet';
import { AuthStyle } from '../../Layouts/style';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { RightArrow, WhiteRightArrow } from '../../Icons/SVGContainer';

const Signup = () => {
  const [location, setLocation] = React.useState('');
  const handleLocationChange = (event) => {
    setLocation(event.target.value);
  };
  const [, setBusinessType] = React.useState('');
  const handleBusinessChange = (event) => {
    setBusinessType(event.target.value);
  };
  return (
    <AuthStyle>
      <Helmet>
        <title>Mirasys: Sign up</title>
      </Helmet>
      <Box className="signup_area">
        <Box className="signup_left">
          <img src="/images/logo.svg" className="logo" />
          <Box className="signup_left_pic"></Box>
        </Box>
        <Box className="signup_right">
          <Typography className="sign_toplink">
            I already have an account <Link to="/login">Login</Link>
          </Typography>
          <Box className="sign_heading">
            <Typography component="h2">Signup</Typography>
            <Typography>Please signup to your account</Typography>
          </Box>
          <form>
            <Box className="form_box_grid">
              <Box className="form_box">
                <Typography component="label">Name</Typography>
                <FormControl fullWidth>
                  <TextField placeholder="William" />
                </FormControl>
              </Box>
              <Box className="form_box">
                <Typography component="label">Surname</Typography>
                <FormControl fullWidth>
                  <TextField placeholder="Shakespeare" />
                </FormControl>
              </Box>
            </Box>
            <Box className="form_box_grid">
              <Box className="form_box">
                <Typography component="label">Business email</Typography>
                <FormControl fullWidth>
                  <TextField
                    type="email"
                    placeholder="william@companymail.com"
                  />
                </FormControl>
              </Box>
              <Box className="form_box">
                <Typography component="label">Company name</Typography>
                <FormControl fullWidth>
                  <TextField placeholder="My company name" />
                </FormControl>
              </Box>
            </Box>
            <Box className="form_box">
              <Typography component="label">Location</Typography>
              <FormControl fullWidth>
                <Select
                  value={location}
                  onChange={handleLocationChange}
                  displayEmpty
                >
                  <MenuItem value="">
                    <em>Select</em>
                  </MenuItem>
                  <MenuItem value={10}>West Bengal</MenuItem>
                  <MenuItem value={20}>MP</MenuItem>
                  <MenuItem value={30}>UP</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box className="form_box">
              <Typography component="label">Business Type</Typography>
              <FormControl fullWidth>
                <Select
                  value={location}
                  onChange={handleBusinessChange}
                  displayEmpty
                >
                  <MenuItem value="">
                    <em>Select</em>
                  </MenuItem>
                  <MenuItem value={10}>Business type 1</MenuItem>
                  <MenuItem value={20}>Business type 2</MenuItem>
                  <MenuItem value={30}>Business type 3</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box className="form_box">
              <Typography component="label">Password</Typography>
              <FormControl fullWidth>
                <TextField type="password" placeholder="Type password" />
              </FormControl>
              <Box className="streanth_info_area">
                <Box className="streanth_box">
                  <Box></Box>
                  <Box></Box>
                  <Box></Box>
                </Box>
                <Typography className="week_pass_msg">Week Password</Typography>
                <Box className="streanth_info_area">
                  <Box component="ul">
                    <Box component="li">Use at least 8 characters</Box>
                    <Box component="li">Use at least 8 characters</Box>
                    <Box component="li">Use at least 8 characters</Box>
                    <Box component="li">Use at least 8 characters</Box>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box className="form_box">
              <Typography component="label">Confirm Password</Typography>
              <FormControl fullWidth>
                <TextField
                  type="password"
                  placeholder="Type confirm password"
                />
              </FormControl>
            </Box>
            <Box className="form_checkbox">
              <Typography component="label">
                <Checkbox type="checkbox" color="success" />
                By signing up, I agree to the Privacy Policy and Terms of
                Service.
              </Typography>
            </Box>
            <Box className="form_box">
              <Button className="submitBtn">
                Create your free account
                <RightArrow />
              </Button>
            </Box>
            <Box className="form_box">
              <Button className="googleBtn">
                <img src="/images/google_icon.svg" /> Sign up with Google
                <WhiteRightArrow />
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
    </AuthStyle>
  );
};

export default Signup;
