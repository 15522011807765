import { Box, IconButton, Typography } from '@mui/material';
import React from 'react';
import { DatatableHeadingStyle } from '../../../Layouts/style';

const AdminBusinessHeading = ({ setModalOpen }) => {
  return (
    <DatatableHeadingStyle>
      <Box className="user_heading business_heading">
        <Typography component="h2">Business</Typography>
        <Box className="table_head_btn_con">
          <IconButton onClick={() => setModalOpen(true)}>
            Create New Business
          </IconButton>
        </Box>
      </Box>
    </DatatableHeadingStyle>
  );
};

export default AdminBusinessHeading;
