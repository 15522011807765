import { useDispatch, useSelector } from 'react-redux';
import { apolloClient } from '../../graphQL/apolloClient';
import { selectUser, selectIsAuthenticated, actions } from '../Slice/auth';
import { LOGIN_MUTATION } from '../../graphQL/auth';
import { toast } from 'react-toastify';

export default function useAuth() {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const isAuthenticated = useSelector(selectIsAuthenticated);

  const login = async ({ email, password }) => {
    try {
      const response = await apolloClient.mutate({
        mutation: LOGIN_MUTATION,
        variables: {
          email: email,
          password: password,
        },
      });
      if ('errors' in response) {
        toast.error(response.errors[0].message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.success('Logged in successfully!', {
          position: toast.POSITION.TOP_RIGHT,
        });
        dispatch(actions.login(response.data.loginUser));
      }
    } catch (error) {
      console.error('Login error:', error);
      if (error.message.includes('User not found')) {
        toast.error('Invalid email or password. Please try again.', {
          position: toast.POSITION.TOP_RIGHT,
        });
      }

      if (error.message.includes('Invalid password')) {
        toast.error('Invalid password. Please try again.', {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
  };

  const logout = async () => {
    dispatch(actions.logout());
  };

  return {
    login,
    logout,
    isAuthenticated,
    user,
  };
}
