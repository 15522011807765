import { Box, Button, TextField, Typography } from '@mui/material';
import React from 'react';
import { ModalLayoutStyle } from '../../Layouts/style';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  createBusinessAdmin,
  updateBusinessAdmin,
} from '../../graphQL/User/mutation';

const validationSchema = Yup.object({
  name: Yup.string()
    .matches(
      /^\s*[a-zA-Z]+(?:\s+[a-zA-Z]+)*\s*$/,
      'Name should only contain letters'
    )
    .required('Name is required')
    .min(3, 'Name must consist atleast 3 characters')
    .max(40, 'Name may consist max 40 characters'),
  surname: Yup.string()
    .transform((value, originalValue) => {
      // Trim spaces before and after the surname
      return originalValue.trim();
    })
    .matches(/^[a-zA-Z]+$/, 'Surname should only contain letters')
    .min(3, 'Surname must consist atleast 3 characters')
    .max(40, 'Surname may consist max 40 characters')
    .required('Surname is required'),
  email: Yup.string()
    .email('Enter a valid email')
    .required('Email is required')
    .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, 'enter valid email'),
  companyName: Yup.string()
    .required('Company Name is required')
    .matches(/^[a-zA-Z_]+(?:\s[a-zA-Z_]+)*$/, 'enter valid email'),
  domainName: Yup.string()
    .required('Domain Name is required')
    .matches(
      /^(?!:\/\/)(?=[a-zA-Z0-9-]{1,63}\.)(xn--)?[a-zA-Z0-9]+(-[a-zA-Z0-9]+)*(\.[a-zA-Z]{2,})+$/,
      'enter valid domain name'
    ),
});

const AddBusinessForm = ({ onClose, fetchBusiness, initialData }) => {
  const formik = useFormik({
    initialValues: {
      name: initialData?.firstname || '',
      surname: initialData?.lastname || '',
      email: initialData?.email || '',
      companyName: initialData?.tenant?.company || '',
      domainName: initialData?.tenant?.subdomain || '',
    },
    validationSchema: validationSchema,
    onSubmit: (values, { setSubmitting }) => {
      try {
        if (initialData?.id) {
          // Updating the business
          updateBusinessAdmin({
            id: parseInt(initialData?.id),
            firstname: values.name,
            lastname: values.surname,
            company: values.companyName,
          })
            .then(() => {
              fetchBusiness();
              onClose();
            })
            .catch((err) => {
              console.log('ERROR:', err);
            });
        } else {
          createBusinessAdmin({
            firstname: values.name.trim(),
            lastname: values.surname.trim(),
            email: values.email.trim(),
            company: values.companyName.trim(),
            subdomain: values.domainName.trim(),
          })
            .then(() => {
              fetchBusiness();
              onClose();
            })
            .catch((err) => {
              console.log('ERROR:', err);
              setSubmitting(false);
            });
        }
      } catch (err) {
        console.log('error:', err);
      }
    },

    handleChange: (event) => {
      const { name, value } = event.target;
      formik.setFieldValue(name, value.trim());
      formik.handleChange(event);
    },
  });
  return (
    <ModalLayoutStyle>
      <Box className="modal_form_body">
        <form onSubmit={formik.handleSubmit}>
          <Box className="form_field_area">
            <Box className="form_box_hold">
              <Box className="form_box_con">
                <Typography component="label">Name</Typography>
                <TextField
                  name="name"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                />
              </Box>
              <Box className="form_box_con">
                <Typography component="label">Surname</Typography>
                <TextField
                  name="surname"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.surname}
                  error={
                    formik.touched.surname && Boolean(formik.errors.surname)
                  }
                  helperText={formik.touched.surname && formik.errors.surname}
                />
              </Box>
            </Box>
            <Box className="form_box_hold">
              <Box className="form_box_con">
                <Typography component="label">Business Email</Typography>
                <TextField
                  name="email"
                  type="email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                  disabled={initialData?.id || false}
                />
              </Box>
              <Box className="form_box_con">
                <Typography component="companyName">Business Name</Typography>
                <TextField
                  name="companyName"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.companyName}
                  error={
                    formik.touched.companyName &&
                    Boolean(formik.errors.companyName)
                  }
                  helperText={
                    formik.touched.companyName && formik.errors.companyName
                  }
                />
              </Box>
            </Box>
            <Box className="form_box_hold">
              <Box className="form_box_con">
                <Typography component="label">Domain Name</Typography>
                <TextField
                  name="domainName"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.domainName}
                  error={
                    formik.touched.domainName &&
                    Boolean(formik.errors.domainName)
                  }
                  helperText={
                    formik.touched.domainName && formik.errors.domainName
                  }
                  disabled={initialData?.id || false}
                />
              </Box>
            </Box>
          </Box>
          <Box className="add_btn">
            <Button className="cancel_btn" onClick={onClose}>
              Cancel
            </Button>
            <Button type="submit" disabled={formik.isSubmitting}>
              {initialData?.id ? 'Update' : 'Create'}
            </Button>
          </Box>
        </form>
      </Box>
    </ModalLayoutStyle>
  );
};

export default AddBusinessForm;
