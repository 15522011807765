import { toast } from 'react-toastify';
import { apolloClient } from '../apolloClient';
import {
  CREATE_BUSINESS,
  UPDATE_BUSINESS,
  UPDATE_BUSINESS_STATUS,
} from '../user';

export const createBusinessAdmin = async ({
  firstname,
  lastname,
  email,
  company,
  subdomain,
}) => {
  try {
    const response = await apolloClient.mutate({
      mutation: CREATE_BUSINESS,
      variables: {
        firstname: firstname,
        lastname: lastname,
        email: email,
        password: 'Password@2023',
        company: company,
        subdomain: subdomain,
      },
    });
    toast.success('Business created successfully!', {
      position: toast.POSITION.TOP_RIGHT,
    });
    return response;
  } catch (error) {
    if (error.message.includes('Invalid firstname or lastname')) {
      toast.error('Invalid firstname or lastname', {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    throw new Error(error.message);
  }
};

export const updateBusinessAdmin = async ({
  id,
  firstname,
  lastname,
  company,
}) => {
  try {
    const response = await apolloClient.mutate({
      mutation: UPDATE_BUSINESS,
      variables: {
        id: id,
        firstname: firstname,
        lastname: lastname,
        company: company,
      },
    });
    toast.success('Business updated successfully!', {
      position: toast.POSITION.TOP_RIGHT,
    });
    return response;
  } catch (error) {
    if (error.message.includes('Invalid firstname or lastname')) {
      toast.error('Invalid firstname or lastname', {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    throw new Error(error.message);
  }
};

export const updateBusinessAdminStatus = async ({ id, status }) => {
  try {
    const response = await apolloClient.mutate({
      mutation: UPDATE_BUSINESS_STATUS,
      variables: {
        id: id,
        status: status,
      },
    });
    toast.success('Business updated successfully!', {
      position: toast.POSITION.TOP_RIGHT,
    });
    return response;
  } catch (error) {
    toast.error(error.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
    throw new Error(error.message);
  }
};