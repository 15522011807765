import { createTheme, responsiveFontSizes } from '@mui/material/styles';

const commonColor = {
  palatte: {
    primary: '#03A415',
    secondary: '#00036E',
    error: '#F00',
    warning: '#F9B959',
    white: '#ffffff',
    black: '#000000',
    success: '#37ad00',
    blue: '#0096ff',
  },
};

const dark = {
  ...commonColor,
  bodyBgColor: '#252C20',
  componentBgColor: '#364030',
  loginButtonBgColor: '#fff',
  loginButtonLightBgColor: '#D9D9D9',
  navbarBgColor: '#191919',
  lightDarkBgColor: '#383838',
  sidebarBgColor: '#383838',
  sidebarHoverBgColor: '#4f4f50',
  error: '#F00',
  darkBtnBgColor: '#242426',
  tableBorderColor: '#D9DDE6',
  tableBorderLightColor: '#5f6a5e',
  tableBodyBgColor: '#2b3b29',
  activeBgColor: '#117137',
  activeTextColor: '#64D662',
  modalHeadingBgColor: '#1f311e',
  headingBgColor: '#2E3529',
  lightTextColor: 'rgba(255, 255, 255, 0.46)',
  lightBorderColor: 'rgba(255, 255, 255, 0.14)',
  dropdownBgColor: '#48533E',

  color: {
    color1: '#FFFFFF',
    color2: '#000',
    color3: '#646464',
  },
  borderColor: {
    color1: '#E2E2E2',
  },
  lightColor: {
    color1: '#E1E1E1',
  },
  textBgColor: {
    color1: '#EEEEEE',
  },
  invertColor: {},
};

const light = {
  ...commonColor,
  bodyBgColor: '#F8FAFC',
  componentBgColor: '#FFFFFF',
  navbarBgColor: '#FFFFFF',
  selectedBgColor: '#F3F9FF',
  tabBtnBgColor: '#E6ECE7',

  color: {
    color1: '#000000',
    color2: '#4B5967',
    color3: '#9B9B9B',
    color4: '#C3C3C3',
    color5: '#eee',
  },
  lightColor: {
    color1: '#E1E1E1',
  },
  borderColor: {
    color1: '#E2E2E2',
    color2: '#3a82e261',
  },
  textBgColor: {
    color1: '#EEEEEE',
  },
  invertColor: {},
};

const createPrimaryTheme = (type = 'dark') => {
  const themeColors = type === 'dark' ? { ...dark } : { ...light };
  let theme = createTheme({
    colors: { ...themeColors },
    palette: {
      primary: {
        main: themeColors.palatte.primary,
      },
      secondary: {
        main: themeColors.palatte.secondary,
      },
      error: {
        main: themeColors.palatte.error,
      },
      success: {
        main: themeColors.palatte.primary,
      },
      warning: {
        main: themeColors.palatte.warning,
      },
      background: {
        paper: themeColors.componentBgColor,
      },
      text: {
        primary: themeColors.color.color1,
        secondary: themeColors.color.color2,
      },
    },
    typography: {
      fontFamily: `'Mulish', sans-serif`,
      h1: {
        fontSize: '2rem',
        fontWeight: '600',
      },
      h2: {
        fontSize: '1.8rem',
        fontWeight: '600',
      },
      h3: {
        fontSize: '1.6rem',
        fontWeight: '600',
      },
      h4: {
        fontSize: '1.4rem',
        fontWeight: '600',
      },
      h5: {
        fontSize: '1.2rem',
        fontWeight: '600',
      },
      h6: {
        fontSize: '1rem',
        fontWeight: '600',
      },
    },
    components: {
      MuiButton: {
        defaultProps: {
          variant: 'contained',
        },

        styleOverrides: {
          root: {
            fontSize: '1rem',
            textTransform: 'capitalize',

            '&&&': {
              boxShadow: 'unset',
            },
          },

          sizeLarge: {
            height: '3.5rem',
          },
        },
      },

      MuiInputBase: {
        styleOverrides: {
          root: {
            '&&&': {
              borderRadius: '0.8rem',
              minHeight: '43px',
            },
          },
        },
      },

      MuiFormGroup: {
        styleOverrides: {
          root: {
            '&&&': {
              marginBottom: '1.4rem',
            },
          },
        },
      },

      MuiPopper: {
        styleOverrides: {
          root: {
            '&&&': {
              zIndex: 99999,
            },
          },
        },
      },

      MuiLink: {
        defaultProps: {
          underline: 'none',
        },
      },

      MuiCard: {
        styleOverrides: {
          root: {
            '&&&': {
              borderRadius: '1.4rem',
              boxShadow: 'none',
              border: `1px solid ${themeColors.borderColor.color1}`,
            },
          },
        },
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 576,
        md: 768,
        lg: 992,
        xl: 1200,
        xxl: 1400,
        sz_1600: 1600,
        sz_1800: 1800,
        sz_1900: 1900,
      },
    },
  });

  theme = responsiveFontSizes(theme);
  return theme;
};

export default createPrimaryTheme;
