import { Box, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import { DashboardIcon, UserIcon } from '../Icons/SVGContainer';

const MenuItems = [
  {
    route: '/dashboard',
    icon: <DashboardIcon />,
    label: 'Dashboard',
  },
  {
    route: '/admin-business',
    icon: <UserIcon />,
    label: 'Business',
  },
];

const Menu = (props) => {
  return (
    <>
      {MenuItems.map((menuItem, index) => {
        if (menuItem.label === 'Business') {
          return (
            <Typography key={menuItem.route} component="li">
              <Link to={menuItem.route}>
                <Box>{menuItem.icon}</Box>
                {menuItem.label}
              </Link>
            </Typography>
          );
        } else {
          return (
            <Typography
              key={menuItem.route}
              component="li"
              style={{ opacity: 0.5 }}
            >
              {/* <Link to={menuItem.route}> */}
              <Box>{menuItem.icon}</Box>
              {menuItem.label}
              {/* </Link> */}
            </Typography>
          );
        }
      })}
    </>
  );
};

export default Menu;
