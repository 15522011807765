import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import AuthLayout from '../Layouts/AuthLayout';
import MainLayout from '../Layouts/MainLayout';
import Login from '../Pages/AuthPage/Login';
import Signup from '../Pages/AuthPage/Signup';
import useAuth from '../Redux/Actions/authActions';
import AdminBusinessList from '../Pages/DashboardPage/AdminBusiness/AdminBusinessList';
import WorkInProgressPage from '../Pages/DashboardPage/WIP';

const AuthGuard = ({ children }) => {
  const { isAuthenticated, user } = useAuth();

  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  if (user.hasChangedPassword === 0) {
    return <Navigate to="/change-password" replace />;
  }

  if (user.isSuperAdmin !== 1) {
    return <WorkInProgressPage />;
  }

  return <>{children}</>;
};

const PublicPageGuard = ({ children }) => {
  const { isAuthenticated } = useAuth();
  console.log('isAuthenticated', isAuthenticated);
  if (isAuthenticated) {
    return <Navigate to="/admin-business" replace />;
  }

  return <>{children}</>;
};

const RouteComponent = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<AuthLayout />}>
          <Route index element={<PublicPageGuard children={<Login />} />} />
          <Route
            path="login"
            element={<PublicPageGuard children={<Login />} />}
          />
          <Route
            path="signup"
            element={<PublicPageGuard children={<Signup />} />}
          />
        </Route>
        <Route path="/" element={<MainLayout />}>
          <Route
            path="admin-business"
            element={<AuthGuard children={<AdminBusinessList />} />}
          />
          {/* Add other authenticated routes within MainLayout */}
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default RouteComponent;
