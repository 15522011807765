import { styled } from '@mui/material';
import { Dialog } from '@mui/material';

export const AuthStyle = styled('div')(({ theme }) => ({
  a: {
    textDecoration: 'none',
  },
  '.MuiInputBase-root': {
    minHeight: '0 !important',
    minWidth: '0 !important',
  },
  '.signup_area': {
    background: theme.colors.bodyBgColor,
    height: '100vh',
    width: '100%',
    display: 'flex',
    alignItems: 'start',
    '.signup_left': {
      height: '100%',
      padding: '160px',
      flex: 1,
      background: theme.colors.componentBgColor,
      position: 'relative',
      '.logo': {
        position: 'absolute',
        top: '20px',
        left: '40px',
      },
      '.signup_left_pic': {
        width: '100%',
        height: '100%',
        background: theme.colors.bodyBgColor,
      },
    },
    '.signup_right': {
      height: '100%',
      padding: '150px 140px',
      flex: 1,
      overflow: 'auto',
      position: 'relative',
      '.sign_toplink': {
        position: 'absolute',
        right: '140px',
        top: '50px',
        '& a': {
          color: theme.colors.loginButtonBgColor,
          fontWeight: 600,
        },
      },
      '.sign_heading': {
        textAlign: 'center',
        paddingBottom: '80px',
        '& h2': {
          fontSize: '30px',
          fontWeight: 600,
          marginBottom: '7px',
        },
        '& p': {
          fontSize: '24px',
        },
      },
      '.form_box_grid': {
        display: 'flex',
        alignItems: 'start',
        gap: '25px',
      },
      '.form_box': {
        marginBottom: '25px',
        flex: 1,
        '& label': {
          fontSize: '18px',
          marginBottom: '6px',
          display: 'block',
          paddingLeft: '5px',
        },
        '.MuiInputBase-root': {
          background: theme.colors.componentBgColor,
        },
        '& input': {
          background: theme.colors.componentBgColor,
          height: '35px',
          borderRadius: '10px',
        },
        '.MuiSelect-select': {
          background: theme.colors.componentBgColor,
          height: '35px',
          borderRadius: '10px',
          display: 'flex',
          alignItems: 'center',
        },
        '.MuiSelect-icon': {
          color: theme.colors.loginButtonBgColor,
        },
        '.check_con': {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingTop: '10px',
        },
        '.checkbox_con': {
          display: 'flex',
          alignItems: 'center',
          '& label': {
            margin: 0,
          },
          '& input': {
            height: 'auto',
            marginRight: '5px',
          },
        },
        '.forget_pass': {
          display: 'flex',
          justifyContent: 'end',
          paddingTop: '10px',
          '& a': {
            color: theme.colors.loginButtonBgColor,
          },
        },
        '& button.submitBtn': {
          width: '100%',
          background: theme.colors.loginButtonLightBgColor,
          color: theme.colors.color.color2,
          position: 'relative',
          height: '60px',
          borderRadius: '10px',
          marginTop: '25px',
          fontSize: '20px',
          '& svg': {
            position: 'absolute',
            right: '20px',
            top: '18px',
          },
        },
        '& button.googleBtn': {
          width: '100%',
          background: theme.colors.color.color2,
          color: theme.colors.color.color1,
          position: 'relative',
          height: '60px',
          borderRadius: '10px',
          fontSize: '20px',
          '& img': {
            marginRight: '10px',
          },
          '& svg': {
            position: 'absolute',
            right: '20px',
            top: '18px',
          },
        },
      },
      '.form_checkbox': {
        marginTop: '20px',
        '& label': {
          display: 'flex',
          alignItems: 'start',
          gap: '10px',
          fontSize: '14px',
          '& span': {
            padding: 0,
            '& svg': {
              width: '26px',
              height: '26px',
              color: theme.colors.loginButtonBgColor,
            },
          },
        },
      },
      '.streanth_info_area': {
        paddingTop: '10px',
        '.streanth_box': {
          display: 'flex',
          alignItems: 'center',
          gap: '10px',
          '& div': {
            borderRadius: '34px',
            border: '1px solid rgba(255, 255, 255, 0.25)',
            background: theme.colors.componentBgColor,
            width: '140px',
            height: '7px',
          },
        },
        '.week_pass_msg': {
          display: 'block',
          color: theme.colors.error,
          marginTop: '10px',
          fontStyle: 'italic',
        },
        '.streanth_info_area': {
          '& ul': {
            padding: '0 0 0 16px',
            margin: 0,
            '& li': {
              marginTop: '10px',
              fontStyle: 'italic',
            },
          },
        },
      },
    },
  },
  '@media (max-width: 1600px)': {
    '.signup_area': {
      '.signup_right': {
        padding: '120px 100px',
        '.sign_toplink': {
          right: '100px',
        },
        '.sign_heading': {
          paddingBottom: '50px',
        },
      },
      '.signup_left': {
        padding: '120px 100px',
      },
    },
  },
  '@media (max-width: 1200px)': {
    '.signup_area': {
      '.signup_right': {
        padding: '120px 60px',
        '.form_box_grid': {
          flexDirection: 'column',
          gap: 0,
          '& div': {
            flex: 1,
            minWidth: '100%',
          },
        },
        '.sign_toplink': {
          right: '60px',
        },
      },
      '.signup_left': {
        padding: '120px 60px',
      },
    },
  },
  '@media (max-width: 992px)': {
    '.sign_heading': {
      '& h2': {
        fontSize: '24px !important',
      },
      '& p': {
        fontSize: '18px !important',
      },
    },
    '.sign_toplink': {
      right: '30px !important',
    },
    '.signup_area': {
      '.signup_right': {
        padding: '120px 30px',
      },
      '.signup_left': {
        padding: '120px 50px',
      },
    },
    '& button.googleBtn': {
      fontSize: '18px !important',
      '& svg': {
        width: '16px',
      },
    },
    '& button.submitBtn': {
      fontSize: '18px !important',
      '& svg': {
        width: '16px',
      },
    },
  },
  '@media (max-width: 767px)': {
    '.signup_area': {
      '.signup_right': {
        padding: '80px 30px 40px',
        '.sign_heading': {
          paddingBottom: '30px',
        },
      },
      '.signup_left': {
        display: 'none',
      },
    },
    '.sign_toplink': {
      top: '30px !important',
    },
    '& input': {
      height: '25px !important',
    },
    '.MuiSelect-select': {
      height: '25px !important',
    },
  },
}));

export const ForgotPasswordStyle = styled('div')(({ theme }) => ({
  a: {
    textDecoration: 'none',
  },
  '.MuiInputBase-root': {
    minHeight: '0 !important',
    minWidth: '0 !important',
  },
  '.forgot_pass_area': {
    background: theme.colors.bodyBgColor,
    height: '100vh',
    width: '100%',
    display: 'flex',
    alignItems: 'start',
    paddingTop: '60px',
    paddingBottom: '30px',
    overflow: 'auto',
    justifyContent: 'center',
    '.forgot_box_con': {
      width: '750px',
      borderRadius: '25px',
      background: theme.colors.componentBgColor,
      padding: '40px 70px 70px',
    },
    '.forgot_heading': {
      textAlign: 'center',
      paddingBottom: '45px',
      '& h2': {
        fontSize: '28px',
        fontWeight: 600,
        marginBottom: '7px',
        marginTop: '45px',
      },
      '& p': {
        fontSize: '20px',
      },
    },
    '.form_box': {
      marginBottom: '25px',
      flex: 1,
      '& label': {
        fontSize: '18px',
        marginBottom: '6px',
        display: 'block',
        paddingLeft: '5px',
      },
      '& input': {
        background: theme.colors.bodyBgColor,
        height: '35px',
        borderRadius: '10px',
      },
      '.MuiInputBase-root': {
        background: theme.colors.bodyBgColor,
      },
      '& button.submitBtn': {
        width: '100%',
        background: theme.colors.loginButtonBgColor,
        color: theme.colors.color.color2,
        position: 'relative',
        height: '60px',
        borderRadius: '10px',
        marginTop: '10px',
        fontSize: '20px',
        '& svg': {
          position: 'absolute',
          right: '20px',
          top: '18px',
        },
      },
    },
    '.forgot_botinfo2': {
      '& p': {
        textAlign: 'center',
        fontSize: '22px',
        '& a': {
          fontWeight: 700,
          color: theme.colors.loginButtonBgColor,
        },
      },
    },
    '.forgot_botinfo': {
      paddingTop: '30px',
      '& p': {
        textAlign: 'center',
        fontSize: '22px',
        '& a': {
          fontWeight: 700,
          color: theme.colors.loginButtonBgColor,
        },
      },
    },
  },
  '@media (max-width: 992px)': {
    '.forgot_pass_area': {
      '.forgot_box_con': {
        width: '650px',
        padding: '40px',
      },
      '.forgot_heading': {
        paddingBottom: '30px',
        '& h2': {
          fontSize: '24px',
          marginTop: '30px',
        },
        '& p': {
          fontSize: '18px',
        },
      },
      '.form_box': {
        marginBottom: '15px',
      },
      '.forgot_botinfo2': {
        '& p': {
          fontSize: '20px',
        },
      },
      '.forgot_botinfo': {
        paddingTop: '20px',
        '& p': {
          fontSize: '20px',
        },
      },
      'button.submitBtn': {
        '& svg': {
          width: '18px',
        },
      },
    },
  },
  '@media (max-width: 767px)': {
    '.forgot_pass_area': {
      '.forgot_box_con': {
        width: 'calc(100% - 40px)',
        padding: '30px',
      },
      '.form_box': {
        '& input': {
          height: '25px',
        },
        'button.submitBtn': {
          height: '52px',
          '& svg': {
            top: '15px',
          },
        },
      },
    },
  },
}));
export const HeaderStyle = styled('div')(({ theme }) => ({
  '.header_area': {
    background: theme.colors.navbarBgColor,
    padding: '20px 60px 20px 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: 'solid 1px',
    borderColor: theme.colors.loginButtonBgColor,
    position: 'fixed',
    width: '100%',
    top: 0,
    left: 0,
    zIndex: 9,
    '.header_logo': {
      width: '120px',
      display: 'flex',
      justifyContent: 'center',
    },
    '.header_left': {
      display: 'flex',
      alignItems: 'center',
      '.header_date': {
        paddingLeft: '25px',
        '& h2': {
          fontSize: '24px',
          fontWeight: 700,
        },
      },
    },
    '.header_right': {
      display: 'flex',
      alignItems: 'center',
      gap: '20px',
      '.head_user_name': {
        display: 'flex',
        alignItems: 'center',
        gap: '7px',
        '.user_pic': {
          width: '45px',
          height: '45px',
          borderRadius: '50%',
          overflow: 'hidden',
          '& img': {
            objectFit: 'cover',
            width: '100%',
            height: '100%',
          },
        },
        '& h4': {
          fontSize: '14px',
        },
      },
      '.header_drop_icon': {
        width: '40px',
        height: '40px',
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: theme.colors.lightDarkBgColor,
      },
    },
  },
  '@media (max-width: 1300px)': {
    '.header_area': {
      padding: '20px 30px 20px 0',
    },
  },
  '@media (max-width: 992px)': {
    '.header_left': {
      '.header_date': {
        '& h2': {
          fontSize: '20px',
        },
      },
    },
    '.head_user_name': {
      '& h4': {
        display: 'none',
      },
      '& h3': {
        display: 'none',
      },
    },
    '.header_right': {
      gap: '12px',
    },
  },
  '@media (max-width: 767px)': {
    '.header_logo': {
      paddingLeft: '70px',
    },
    '.header_date': {
      display: 'none',
    },
    '.header_area': {
      padding: '20px 20px 20px 0',
    },
  },
}));
export const SidebarStyle = styled('div')(({ theme }) => ({
  a: {
    textDecoration: 'none',
  },
  '.sidebar': {
    width: '120px',
    background: theme.colors.sidebarBgColor,
    height: 'calc(100vh - 102px)',
    overflow: 'auto',
    borderRight: 'solid 1px',
    borderColor: theme.colors.loginButtonBgColor,
    position: 'fixed',
    top: '102px',
    left: 0,
    '& ul': {
      margin: 0,
      padding: '30px 22px',
      '& li': {
        display: 'block',
        textAlign: 'center',
        fontSize: '12px',
        margin: '10px 0',
        '& a': {
          color: theme.colors.loginButtonBgColor,
          '& div': {
            width: '50px',
            height: '50px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            margin: '0 auto 15px',
            borderRadius: '10px',
            '& svg': {
              filter: 'grayscale(1) invert(1)',
            },
          },
        },
        '&:hover': {
          '& a': {
            '& div': {
              background: theme.colors.sidebarHoverBgColor,
            },
          },
        },
      },
    },
  },
  '.menu_toggle_btn': {
    display: 'none',
    zIndex: 9,
    background: theme.colors.loginButtonBgColor,
    top: '30px',
    left: '20px',
    width: '40px',
    height: '40px',
    position: 'fixed',
  },
  '@media (max-width: 767px)': {
    '.menu_toggle_btn': {
      display: 'block',
    },
    '.sidebar': {
      left: '-120px',
      transition: 'all 500ms ease-in-out 0ms',
      zIndex: 9,
    },
    '.sidebar.sidebar_open': {
      left: '0',
      transition: 'all 500ms ease-in-out 0ms',
    },
  },
}));
export const DatatableHeadingStyle = styled('div')(({ theme }) => ({
  '.user_heading': {
    paddingBottom: '25px',
    '& h2': {
      fontSize: '24px',
      marginBottom: '20px',
    },
    '.user_head_search': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      '.MuiInputBase-root': {
        background: theme.colors.componentBgColor,
      },
      '& input': {
        height: '35px',
        borderRadius: '10px',
        padding: '15px',
        width: '280px',
      },
      '.MuiSelect-select': {
        background: theme.colors.componentBgColor,
        height: '20px',
        borderRadius: '10px',
        display: 'flex',
        alignItems: 'center',
        width: '140px',
      },
      '.MuiSelect-icon': {
        color: theme.colors.loginButtonBgColor,
      },
      '& button': {
        background: theme.colors.darkBtnBgColor,
        padding: '12px 20px',
        borderRadius: '10px',
        color: theme.colors.loginButtonBgColor,
        fontSize: '16px',
        height: '56px',
        '& svg': {
          marginRight: '5px',
        },
        '& img': {
          width: '18px',
          marginRight: '7px',
          filter: 'grayscale(1) invert(1)',
        },
      },
      '.table_head_btn_con': {
        display: 'flex',
        alignItems: 'end',
        gap: '10px',
      },
    },
    '.page_search': {
      '& button': {
        padding: 0,
        background: 'none',
        position: 'absolute',
        right: '10px',
      },
    },
    '@media (max-width: 1300px)': {
      '.user_head_search': {
        flexDirection: 'column',
        alignItems: 'start',
        gap: '20px',
        '.table_head_btn_con': {
          width: '100%',
          justifyContent: 'end',
        },
        '.MuiSelect-select': {
          width: '100px',
        },
        '& button': {
          padding: '12px 10px',
        },
      },
    },
    '@media (max-width: 767px)': {
      '.user_head_search': {
        flexDirection: 'column',
        alignItems: 'start',
        gap: '15px',
        '& div': {
          width: '100%',
        },
        '& input': {
          width: '100%',
        },
        '.table_head_btn_con': {
          flexWrap: 'wrap',
          gap: '15px',
        },
      },
    },
  },
  '.business_heading': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& h2': {
      margin: 0,
    },
    '& button': {
      background: theme.colors.loginButtonBgColor,
      padding: '12px 35px',
      borderRadius: '10px',
      color: theme.colors.darkBtnBgColor,
      fontSize: '16px',
      height: '56px',
      '& svg': {
        marginRight: '5px',
      },
      '& img': {
        width: '18px',
        marginRight: '7px',
        filter: 'grayscale(1) invert(1)',
      },
    },
  },
}));
export const UserStyle = styled('div')(({ theme }) => ({}));
export const DialogStyle = styled(Dialog)(({ theme }) => {
  return {
    '.modal-head': {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      alignItems: 'center',
      padding: '20px 35px',
      backgroundColor: theme.colors.modalHeadingBgColor,

      '.global_modal_title': {
        fontSize: '24px',
        fontWeight: 700,
        textTransform: 'capitalize',
      },

      svg: {
        fontSize: '2rem',
      },
    },

    '.modal-body': {
      overflow: 'auto',
      '.body': {
        padding: '35px',
      },

      '.action': {
        padding: '20px 35px',
        float: 'right',
        display: 'flex',
        alignItems: 'center',
        gap: '15px',

        '.cancle': {
          fontSize: '2.2rem',
          fontWeight: 500,
          letterSpacing: '0.1rem',

          svg: {
            fontSize: '2.2rem',
          },

          '&:hover': {
            background: 'none',
          },
        },

        '.save': {
          fontSize: '2.2rem',
          fontWeight: 500,
          letterSpacing: '0.1rem',
          padding: '3px 40px',
        },
      },
    },
    '@media (max-width: 767px)': {
      '.modal-head': {
        padding: '20px',
        '.global_modal_title': {
          fontSize: '22px',
        },
      },
      '.form_field_area': {
        padding: '25px 20px',
      },
      '.add_btn': {
        padding: '20px',
      },
    },
  };
});
export const ModalLayoutStyle = styled('div')(({ theme }) => ({
  '.modal_form_body': {
    '.form_field_area': {
      padding: '30px 40px',
      backgroundColor: theme.colors.modalComponentBgColor,
      '.form_box_hold': {
        display: 'flex',
        alignItems: 'start',
        gap: '20px',
        '.form_box_con': {
          flex: 1,
          width: '100%',
          '& div': {
            minWidth: '100%',
          },
          '& button': {
            padding: 0,
            minWidth: 0,
            background: 'none',
          },
        },
      },
      '.MuiAlert-root': {
        minWidth: '100% !important',
        marginTop: '8px',
        fontSize: '16px!important',
        '.MuiAlert-icon': {
          fontSize: '25px',
        },
      },
    },
    '& label': {
      fontSize: '16px',
      fontWeight: 500,
      lineHeight: '20px',
      marginBottom: '10px',
      display: 'block',
    },
    '& input': {
      height: '40px',
      padding: '8px 15px',
      fontSize: '16px',
      borderRadius: 'inherit',
      border: 'solid 1px',
      borderColor: theme.colors.tableBorderColor,
      '&:disabled': {
        color: theme.colors.loginButtonBgColor,
      },
    },
    '.MuiInputBase-root': {
      width: '100%',
    },
    '.MuiSelect-select': {
      height: '25px',
      borderRadius: '10px',
      display: 'flex',
      alignItems: 'center',
      border: 'solid 1px',
      borderColor: theme.colors.tableBorderColor,
    },
    '.MuiSelect-icon': {
      color: theme.colors.loginButtonBgColor,
    },
    '.form_box_con': {
      marginBottom: '20px',
    },
    '.add_icon': {
      color: theme.colors.loginButtonBgColor,
      fontSize: '24px',
      fontWeight: 600,
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
    },
    '.add_btn': {
      display: 'flex',
      justifyContent: 'end',
      padding: '25px 40px',
      backgroundColor: theme.colors.modalHeadingBgColor,
      gap: '15px',
      '& button': {
        width: '190px',
        height: '54px',
        fontSize: '18px',
        fontWeight: 600,
        background: theme.colors.palatte.white,
        color: theme.colors.modalHeadingBgColor,
      },
      '.cancel_btn': {
        color: theme.colors.palatte.white,
        background: theme.colors.componentBgColor,
      },
    },
    '.camera_info_area': {
      padding: '30px 40px',
    },
    '.camera_info': {
      marginBottom: '20px',
      '& h4': {
        color: theme.colors.lightTextColor,
        fontSize: '13px',
        fontWeight: 600,
        textTransform: 'uppercase',
        marginBottom: '5px',
      },
      '& h3': {
        color: theme.colors.loginButtonBgColor,
        fontSize: '17px',
      },
    },
    '@media (max-width: 767px)': {
      '.form_box_hold': {
        flexDirection: 'column',
        gap: '0 !important',
      },
    },
  },
}));
export const ChangePasswordStyle = styled('div')(({ theme }) => ({
  '.heading': {
    paddingBottom: '25px',
    '& h2': {
      fontSize: '24px',
      marginBottom: '20px',
    },
  },
  '.change_pass_con': {
    width: '700px',
    padding: '40px',
    background: theme.colors.darkBtnBgColor,
    borderRadius: '20px',
    margin: '0 auto',
    '.form_box': {
      marginBottom: '20px',
      flex: 1,
      '& label': {
        fontSize: '18px',
        marginBottom: '6px',
        display: 'block',
        paddingLeft: '5px',
      },
      '.MuiInputBase-root': {
        background: theme.colors.componentBgColor,
      },
      '& input': {
        background: theme.colors.componentBgColor,
        height: '35px',
        borderRadius: '10px',
      },
      '& button.submitBtn': {
        width: '100%',
        background: theme.colors.loginButtonLightBgColor,
        color: theme.colors.color.color2,
        position: 'relative',
        height: '60px',
        borderRadius: '10px',
        marginTop: '25px',
        fontSize: '20px',
      },
    },
  },
  '@media (max-width: 1300px)': {
    '.change_pass_con': {
      width: '600px',
      padding: '30px',
    },
  },
  '@media (max-width: 767px)': {
    '.change_pass_con': {
      width: '100%',
      padding: '30px',
    },
  },
}));
export const MainLayoutStyle = styled('div')(({ theme }) => ({
  '.main_area': {
    background: theme.colors.bodyBgColor,
    minHeight: '100vh',
    width: '100%',
    padding: '130px 60px 0 150px',
  },
  '@media (max-width: 1300px)': {
    '.main_area': {
      padding: '130px 30px 0 150px',
    },
  },
  '@media (max-width: 767px)': {
    '.main_area': {
      padding: '130px 20px 0 20px',
    },
  },
}));
export const PermissionStyle = styled('perm_areadiv')(({ theme }) => ({
  '.page_heading': {
    '& button': {
      color: theme.colors.loginButtonBgColor,
      fontSize: '22px',
      fontWeight: 500,
      display: 'flex',
      alignItems: 'center',
      gap: '15px',
    },
  },
  '.permission_heading': {
    display: 'flex',
    alignItems: 'center',
    background: theme.colors.componentBgColor,
    margin: '25px 0 15px',
    borderRadius: '10px',
    '.permission_name_hold': {
      width: '320px',
      padding: '20px',
      borderRight: `solid 1px ${theme.colors.loginButtonBgColor}`,
      '& h3': {
        fontSize: '20px',
        fontWeight: '500',
      },
    },
    '.permission_name_hold_right': {
      width: 'calc(100% - 320px)',
      padding: '20px',
      '& h3': {
        fontSize: '20px',
        fontWeight: '500',
      },
    },
  },
  '.permission_box_con': {
    display: 'flex',
    alignItems: 'start',
    padding: '15px 0 0 15px',
    position: 'relative',

    '&:last-child': {
      border: 'none',
    },
    '.permission_name': {
      width: '300px',
      background: theme.colors.componentBgColor,
      padding: '20px',
      borderRadius: '10px 0 0 10px',
      borderRight: `solid 1px ${theme.colors.loginButtonBgColor}`,
      height: 'calc(100% - 15px)',
      position: 'absolute',
      '& h3': {
        fontSize: '20px',
        fontWeight: '500',
      },
    },
    '.permission_box': {
      width: 'calc(100% - 300px)',
      marginLeft: '300px',
      display: 'flex',
      flexDirection: 'column',
      gap: '30px',
      '.permission_box_con': {
        background: theme.colors.componentBgColor,
        padding: '20px',
        '.MuiFormGroup-root': {
          margin: 0,
          padding: 0,
        },
      },
      '& h4': {
        color: ' #64748B',
        fontSize: '20px',
        fontWeight: 300,
      },
      '.MuiFormGroup-root': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '30px 100px',
        paddingTop: '7px',
        '& label': {
          display: 'flex',
          alignItems: 'center',
          gap: '10px',
          margin: '0 0 0 -2px',
        },
      },
    },
  },
  '.add_btn': {
    display: 'flex',
    justifyContent: 'end',
    padding: '40px 0',
    gap: '15px',
    '& button': {
      width: '190px',
      height: '54px',
      fontSize: '18px',
      fontWeight: 600,
      border: 'none',
      boxShadow: 'none',
      borderRadius: '18px',
      background: theme.colors.darkBtnBgColor,
    },
  },
  '@media (max-width: 992px)': {
    '.permission_name': {
      width: '250px !important',
    },
    '.permission_box': {
      width: 'calc(100% - 250px) !important',
      marginLeft: '250px !important',
    },
    '.MuiFormGroup-root': {
      gap: '20px 60px !important',
    },
    '.permission_heading': {
      '.permission_name_hold': {
        width: '265px',
      },
      '.permission_name_hold_right': {
        width: 'calc(100% - 265px)',
      },
    },
  },
  '@media (max-width: 767px)': {
    '.permission_box_con': {
      flexDirection: 'column',
      '.permission_name': {
        width: '100% !important',
        borderRadius: '10px',
        borderRight: `none`,
        borderBottom: `solid 1px ${theme.colors.loginButtonBgColor}`,
        height: 'auto',
        position: 'relative',
        marginBottom: '15px',
        '& h3': {
          fontSize: '18px',
          fontWeight: '500',
        },
      },
      '.permission_box': {
        width: '100% !important',
        marginLeft: '0 !important',
        gap: '20px',
        '.permission_box_con': {
          background: theme.colors.componentBgColor,
          padding: '20px',
          '.MuiFormGroup-root': {
            margin: 0,
            padding: 0,
          },
        },
        '& h4': {
          fontSize: '18px',
        },
        '.MuiFormGroup-root': {
          gap: '20px !important',
        },
      },
    },
  },
  '@media (max-width: 600px)': {
    '.permission_heading': {
      flexDirection: 'column',
      '.permission_name_hold': {
        width: '100%',
        borderRight: 'none',
        borderBottom: `solid 1px ${theme.colors.loginButtonBgColor}`,
      },
      '.permission_name_hold_right': {
        width: '100%',
      },
    },
  },
}));
export const ApplicationStyle = styled('perm_areadiv')(({ theme }) => ({
  '.application_page_heading': {
    padding: '30px',
    background: theme.colors.headingBgColor,
    width: 'calc(100% + 90px)',
    margin: '-27px 0 30px -30px',
    '@media (max-width: 1300px)': {
      width: 'calc(100% + 58px)',
    },
    '& h2': {
      color: theme.colors.loginButtonBgColor,
      fontSize: '22px',
      fontWeight: 500,
    },
  },
  '.apply_box_area': {
    display: 'flex',
    alignItems: 'start',
    gap: '30px 2%',
    flexWrap: 'wrap',
    '.apply_box': {
      background: theme.colors.componentBgColor,
      width: '30.33%',
      borderRadius: '10px',
      '.apply_box_heading': {
        padding: '15px 65px 15px 20px',
        borderBottom: `solid 1px ${theme.colors.lightBorderColor}`,
        position: 'relative',
      },
      '.application_menu': {
        position: 'absolute',
        right: '15px',
        top: '40px',
      },
      '& h4': {
        color: theme.colors.lightTextColor,
        fontSize: '13px',
        fontWeight: 600,
        textTransform: 'uppercase',
        marginBottom: '5px',
      },
      '& h3': {
        color: theme.colors.loginButtonBgColor,
        fontSize: '20px',
        wordBreak: 'break-all',
      },
      '.apply_box_info': {
        display: 'flex',
        flexDirection: 'column',
        gap: '25px',
        padding: '20px 25px',
      },
    },
    '@media (max-width: 1500px)': {
      '.apply_box': {
        width: '48%',
      },
    },
    '@media (max-width: 992px)': {
      '.apply_box_area': {
        gap: '20px 0',
      },
      '.apply_box': {
        width: '100%',
      },
    },
  },
}));
