import { ApolloClient, InMemoryCache, HttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { store } from '../Redux/store';
const httpLink = new HttpLink({
  uri: process.env.REACT_APP_API_URL_CENTRAL,
  //uri: 'http://mirasys_api_gateway.localhost/graphql',
});

const authLink = setContext((_, { headers }) => {
  const token = store.getState().auth.token; // Access the token from the Redux store

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
};

export const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache().restore({}),
  defaultOptions: defaultOptions, // Apply the default options here
});
