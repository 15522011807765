import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Box } from '@mui/material';
import { UserStyle } from '../../../Layouts/style';
import BaseModal from '../../../Components/Modal/BaseModal';
import AdminBusinessHeading from './AdminBusinessHeading';
import AddBusinessForm from '../../../Components/Form/AddBusinessForm';
import BusinessDataTable from '../../../Components/DataTable/BusinessDatatable';
import { getAllBusiness } from '../../../graphQL/User/query';

const AdminBusinessList = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [businessList, setBusinessList] = useState([]);
  const [editIntialData, setEditIntialData] = useState({});

  useEffect(() => {
    fetchBusiness();
  }, []);

  const fetchBusiness = () => {
    getAllBusiness()
      .then((resp) => {
        if (resp) {
          const { data } = resp;
          if (data && data.getBusinessUsers) {
            setBusinessList(data.getBusinessUsers);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleEditForm = (business) => {
    setEditIntialData(business);
    setModalOpen(true);
  };

  const handleModelClose = () => {
    setEditIntialData({});
    setModalOpen(false);
  };

  const headCells = [
    {
      id: 'fullName',
      numeric: false,
      disablePadding: true,
      label: 'Full Name',
    },
    {
      id: 'emailAddress',
      numeric: false,
      disablePadding: true,
      label: 'Email address',
    },
    {
      id: 'companyName',
      numeric: false,
      disablePadding: true,
      label: 'Company Name',
    },
    {
      id: 'subdomain',
      numeric: false,
      disablePadding: true,
      label: 'Subdomain',
    },
    {
      id: 'status',
      numeric: false,
      disablePadding: true,
      label: 'Status',
    },
    {
      id: 'action',
      numeric: false,
      disablePadding: true,
      label: 'Action',
    },
  ];
  return (
    <UserStyle>
      <Helmet>
        <title>Mirasys: Business</title>
      </Helmet>
      <Box className="user_area">
        <AdminBusinessHeading setModalOpen={setModalOpen} />
        <BusinessDataTable
          rows={businessList}
          headCells={headCells}
          openForm={(business) => handleEditForm(business)}
          fetchBusiness={fetchBusiness}
        />
        {/* <Stack spacing={2}>
                    <Pagination
                        count={totalPages}
                        page={page}
                        onChange={handlePageChange}
                        color="primary"
                    />
                </Stack> */}
        <BaseModal
          open={modalOpen}
          onClose={handleModelClose}
          title={editIntialData?.email ? `Edit business` : `Add new business`}
        >
          <AddBusinessForm
            onClose={() => setModalOpen(false)}
            fetchBusiness={fetchBusiness}
            initialData={editIntialData}
          />
        </BaseModal>
      </Box>
    </UserStyle>
  );
};

export default AdminBusinessList;
