import { gql } from '@apollo/client';

export const GET_USERS = gql`
  query GetUser {
    getUsers {
      email
    }
  }
`;

export const LOGIN_MUTATION = gql`
  mutation UserLogin($email: String!, $password: String!) {
    loginUser(email: $email, password: $password) {
      user {
        email
        firstname
        lastname
        email
        id
        userType
        isSuperAdmin
      }
      token
    }
  }
`;

export const FORGOT_PASSWORD_MUTATION = gql`
  mutation Mutation($email: String!) {
    forgotPassword(email: $email)
  }
`;

export const RESET_PASSWORD_MUTATION = gql`
  mutation Mutation(
    $access_token: String!
    $password: String!
    $confirmPassword: String!
  ) {
    resetTenantUserPassword(
      passwordResetToken: $passwordResetToken
      password: $password
      confirmPassword: $confirmPassword
    )
  }
`;
