import { apolloClient } from '../apolloClient';
import { GET_ALL_BUSINESS } from '../user';

export const getAllBusiness = async () => {
  try {
    const response = await apolloClient.query({
      query: GET_ALL_BUSINESS,
    });
    return response;
  } catch (error) {
    console.error('Login error:', error);
    // Handle login error
  }
};
