import React from 'react';
import RouteComponent from './Routes/Route';
import { ThemeProvider } from '@mui/material';
import createPrimaryTheme from './theme';
import GlobalStyles from '@mui/material/GlobalStyles';
import Styles from './GlobalStyle';
import './index.css';
import { loadErrorMessages, loadDevMessages } from '@apollo/client/dev';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function App() {
  const theme = createPrimaryTheme('dark');

  // if (__DEV__) {
  // Adds messages only in a dev environment
  loadDevMessages();
  loadErrorMessages();
  // }
  return (
    <>
      <ThemeProvider theme={theme}>
        <GlobalStyles styles={Styles(theme)} />
        <RouteComponent />
        <ToastContainer />
      </ThemeProvider>
    </>
  );
}

export default App;
