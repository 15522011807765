const Styles = (theme) => {
  return {
    html: {
      fontSize: '16px',

      '@media(min-width: 1921px)': {
        fontSize: '20px',
      },
    },

    'html,body': {
      padding: 0,
      margin: 0,
      boxSizing: 'border-box',
    },

    '*': {
      boxSizing: 'border-box',
    },

    body: {
      color: theme.colors.color.color1,
      fontFamily: `'Mulish', sans-serif`,
      fontSize: '1rem',
    },
    '.table_head': {
      background: theme.colors.tableBodyBgColor,
      '& th': {
        padding: '20px',
        borderRight: 'solid 1px',
        borderBottom: 'solid 15px',
        borderRightColor: theme.colors.tableBorderColor,
        borderBottomColor: theme.colors.bodyBgColor,
        fontSize: '18px',
        fontWeight: 700,
        '&:last-child': {
          borderRight: 'none',
        },
      },
    },
    '.datatable_common': {
      '& tr': {
        background: theme.colors.tableBodyBgColor,
      },
      '& td': {
        padding: '20px',
        textAlign: 'left',
        borderRight: 'solid 1px',
        borderTop: 'solid 1px',
        borderBottom: 'none',
        fontSize: '18px',
        borderColor: theme.colors.tableBorderLightColor,
        '&:last-child': {
          borderRight: 'none',
        },
        '.task_name': {
          display: 'flex',
          alignItems: 'center',
          gap: '10px',
        },
        '.active_data': {
          background: theme.colors.activeBgColor,
          color: theme.colors.activeTextColor,
          padding: '6px 10px',
        },
        '.action_icon': {
          display: 'flex',
          alignItems: 'center',
          gap: '10px',
        },
      },
    },
    '.profile_toggle': {
      '& a': {
        width: '100%',
        color: theme.colors.loginButtonBgColor,
        textDecoration: 'none',
      },
      '.MuiPaper-elevation': {
        right: '30px !important',
        top: '70px !important',
        width: '180px !important',
        background: theme.colors.componentBgColor,
        '& li': {
          fontSize: '16px',
        },
      },
    },
    ".apply_box_menu": {
      '& a': {
        width: '100%',
        color: theme.colors.loginButtonBgColor,
        textDecoration: 'none',
      },
      '.MuiPaper-elevation': {
        right: '30px !important',
        width: '180px !important',
        background: theme.colors.dropdownBgColor,
        padding: "30px 5px",
        '& li': {
          fontSize: '16px',
          padding: 0,
          "& a": {
            padding: "10px 20px",
            borderRadius: "5px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            "& svg": {
              width: "14px",
            },
            "&:hover": {
              background: theme.colors.componentBgColor,
            }
          }
        },
      },
    },
    '.MuiPagination-root': {
      display: 'flex',
      justifyContent: 'end',
      paddingTop: '25px',
      '& ul': {
        '& li': {
          '.Mui-selected': {
            color: theme.colors.bodyBgColor,
            background: theme.colors.loginButtonBgColor,
          },
        },
      },
    },
    '.MuiBackdrop-root': {
      backgroundColor: '#0000000d !important',
    },
    ".MuiSwitch-root":{
      width: "80px !important",
      height: "42px !important",
      marginLeft: "-5px",
      ".MuiSwitch-track": {
        background: theme.colors.loginButtonBgColor,
        opacity: 0.5,
      },
      ".MuiSwitch-thumb": {
        width: "22px",
        height: "22px",
        marginTop: "1px",
      },
      ".MuiSwitch-switchBase.Mui-checked":{
        color: theme.colors.loginButtonBgColor,
        marginLeft: "20px",
      },
    },
    ".MuiAvatarGroup-root .MuiAvatar-root": {
      border: "none !important",
      margin: "0 8px 0 0 !important",
      minWidth: "42px",
      width: "auto",
      borderRadius: "50px",
      background: theme.colors.bodyBgColor,
      padding: "5px 15px",
      "&.last": {
        background: "#1651EB",
      }
    },
    ".MuiButtonBase-root-MuiCheckbox-root": {
      color: `${theme.colors.loginButtonBgColor} !important`,
    },
  };
};

export default Styles;
