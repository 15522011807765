import React, { useState, useEffect } from 'react';
import {
  Avatar,
  AvatarGroup,
  Box,
  Chip,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Button,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import {
  ActiveIcon,
  DeleteIcon,
  EditIcon,
  OpenBorderEyeIcon,
  SettingIcon,
} from '../../Icons/SVGContainer';

import { updateBusinessAdminStatus } from '../../graphQL/User/mutation';

function EnhancedTableHead(props) {
  const { headCells } = props;

  return (
    <TableHead>
      <TableRow className="table_head">
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const BusinessDataTable = ({ rows, headCells, openForm, fetchBusiness }) => {
  const [rowStatus, setRowStatus] = useState({});
  const visibleRows = rows;

  const handleStatusChange = (e, row) => {
    const newStatus = e.target.checked ? 'Active' : 'Inactive';

    const handleSwitcherChange = (isChecked) => {
      setRowStatus((prevStatus) => ({
        ...prevStatus,
        [row.id]: isChecked ? 'Active' : 'Inactive',
      }));
    };

    // Function to revert the switcher state if confirmation is canceled
    const revertSwitcherState = () => {
      handleSwitcherChange(row.status === 'Active');
    };
    const confirmation = window.confirm(
      'Are you sure you want to change status?'
    );

    if (confirmation) {
      handleSwitcherChange(e.target.checked);
      updateBusinessAdminStatus({
        id: row.id,
        status: newStatus,
      })
        .then((res) => {
          fetchBusiness();
        })
        .catch((err) => {
          console.log('Error in Updating Status', err);
          revertSwitcherState();
        });
    } else {
      revertSwitcherState();
    }
  };

  useEffect(() => {
    // Set the initial switcher status when rows change
    if (rows.length > 0) {
      const initialStatus = rows.reduce(
        (statusObj, row) => ({
          ...statusObj,
          [row.id]: row.status === 'Active' ? 'Active' : 'Inactive',
        }),
        {}
      );
      setRowStatus(initialStatus);
    }
  }, [rows]);

  return (
    <TableContainer>
      {visibleRows && visibleRows.length === 0 ? (
        <Typography
          variant="h4"
          style={{ textAlign: 'center', marginTop: '20px' }}
        >
          No data/business found
        </Typography>
      ) : (
        <Table
          sx={{ minWidth: 750 }}
          aria-labelledby="tableTitle"
          size={'medium'}
          className="datatable_common"
        >
          <EnhancedTableHead rowCount={rows.length} headCells={headCells} />
          <TableBody>
            {visibleRows.map((row) => {
              return (
                <TableRow
                  hover
                  tabIndex={-1}
                  key={row.id}
                  sx={{ cursor: 'pointer' }}
                >
                  <TableCell align="right">
                    <Box className="task_name">
                      <Typography component="span">{`${row.firstname} ${row.lastname}`}</Typography>
                    </Box>
                  </TableCell>
                  <TableCell align="right">
                    <Box className="task_name">{row.email}</Box>
                  </TableCell>
                  <TableCell align="right">
                    <Box className="task_name">{row.tenant?.company}</Box>
                  </TableCell>
                  <TableCell align="right">
                    <Box className="task_name">{row.tenant?.subdomain}</Box>
                  </TableCell>
                  <TableCell align="right">
                    <Box className="task_name">
                      <FormControlLabel
                        control={
                          <Switch
                            checked={rowStatus[row.id] === 'Active'}
                            onChange={(e) => handleStatusChange(e, row)}
                          />
                        }
                      />
                      {row.status === 'Active' ? (
                        <Chip
                          icon={<ActiveIcon />}
                          label={row.status}
                          className="active_data"
                        />
                      ) : (
                        <Chip
                          icon={<ActiveIcon />}
                          label={row.status}
                          className="inactive_data"
                        />
                      )}
                    </Box>
                  </TableCell>
                  <TableCell align="right">
                    <IconButton onClick={() => openForm(row)}>
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      )}
    </TableContainer>
  );
};

export default BusinessDataTable;
