import { gql } from '@apollo/client';

export const CREATE_BUSINESS = gql`
  mutation CreateBusiness(
    $firstname: String!
    $lastname: String!
    $email: String!
    $password: String!
    $company: String!
    $subdomain: String!
  ) {
    createBusiness(
      firstname: $firstname
      lastname: $lastname
      email: $email
      password: $password
      company: $company
      subdomain: $subdomain
    ) {
      id
      email
      firstname
      lastname
      userType
      tenant {
        id
        company
        subdomain
      }
    }
  }
`;

export const UPDATE_BUSINESS = gql`
  mutation UpdateBusiness(
    $id: ID!
    $firstname: String
    $lastname: String
    $company: String
  ) {
    editBusinessUser(
      id: $id
      firstname: $firstname
      lastname: $lastname
      company: $company
    ) {
      id
      firstname
      lastname
      tenant {
        company
      }
    }
  }
`;

export const UPDATE_BUSINESS_STATUS = gql`
  mutation UpdateBusinessStatus($id: ID!, $status: String!) {
    updateBusinessStatus(id: $id, status: $status) {
      id
      status
    }
  }
`;

export const GET_ALL_BUSINESS = gql`
  query GetBusinessUsers {
    getBusinessUsers {
      id
      firstname
      lastname
      email
      userType
      status
      tenant {
        id
        company
        subdomain
      }
    }
  }
`;
